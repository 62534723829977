require('bootstrap-sass-loader!../bootstrap/bootstrap-sass.config.js');

require("slick-carousel");

# require("../app/popup_page.coffee");
require("../pages/team.coffee");
require("../app/popup_img.coffee");
# require('../app/video.coffee');

$(document).ready ->
  # new window.PopupPage()
  new window.HomePage()
  new window.PopupImg()
  # new window.Video()


class window.HomePage
  constructor: ->
    self = this
    @initSlider()
#    @initLogo()
    setTimeout( ->
      ymaps.ready self.initMap()
    , 1500)

    $(window).on 'scroll':->
      self.initLogo()

  initSlider: ->
    if $(window).width() > 768
      $('.home-command__slider').not('.slick-initialized').slick({
        arrows: true,
        nextArrow: '<i class="home-command__slider-arrow home-command__slider-arrow--right"></i>',
        prevArrow: '<i class="home-command__slider-arrow home-command__slider-arrow--left"></i>',
        centerMode: true,
        swipe: false,
        variableWidth: true
      });

    $(window).on('resize orientationChange', () ->
      if $(window).width() < 768
        $('.slider__person').slick('unslick');

        if $(window).width() < 425
          $('.slider__awards').slick('unslick');

      else
        $('.home-command__slider').not('.slick-initialized').slick({
          arrows: true,
          nextArrow: '<i class="home-command__slider-arrow home-command__slider-arrow--right"></i>',
          prevArrow: '<i class="home-command__slider-arrow home-command__slider-arrow--left"></i>',
          centerMode: true,
          variableWidth: true
        });
    )

    $(".home-slider__arrow-list-bottom").click (event) ->
      event.preventDefault();
      id  = $(this).attr('href');
      top = $(id).offset().top;
      $('body,html').animate({scrollTop: top}, 700);


  initLogo: ->
    topHeight = $(window).scrollTop();
    footerHeight = $('footer').offset().top - 70

    if $('#home-command').length > 0
      homeComandHeight = $('#home-command').offset().top - 70
      if topHeight > homeComandHeight
        $('.header__logo').addClass('header__logo--min');
        $('.header__logo').removeClass('header__logo--white');
      else
        $('.header__logo').removeClass('header__logo--min');
        $('.header__logo').removeClass('header__logo--white');

    if $('.publications-block').length > 0
      publicationBlock = $('.publications-block').offset().top - 70
      if topHeight > publicationBlock
        $('.header__logo').addClass('header__logo--in-white-backgr');
      else
        $('.header__logo').removeClass('header__logo--in-white-backgr');

    if footerHeight <= topHeight
      $('.header__logo').addClass('header__logo--white');


  initMap: ->
    ScreenWidth = screen.width;
    if ScreenWidth > 767 and ScreenWidth < 992
      centerMap = [
        55.35043892
        86.10202074
      ]
    else
      centerMap = [
        55.3504584
        86.1064314
      ]
    myMap = new (ymaps.Map)('map', {
      center: centerMap
      zoom: 16
      controls: []
    });
    markerImage = {
      iconLayout: 'default#image',
      iconImageHref: '/assets/images/svg/icon-map.svg',
      iconImageSize: [60, 70],
      iconImageOffset: [-45, -70]
    }
    markerCoodinate = [
      55.3504584
      86.1064314
    ]
    myPlacemark1 = new ymaps.Placemark(markerCoodinate, {}, markerImage);
    myMap.behaviors.disable 'scrollZoom'
    myMap.behaviors.disable 'drag'
    myMap.geoObjects.add myPlacemark1
    return