require("../app/tab.coffee");
require("SimpleBarJs");
require("SimpleBarCss");
require("slick-carousel");
require("../app/video.coffee");
require("../app/news_popup_page.coffee");


class window.PopupPage
    constructor: ->
        self = this
        window.defaultPageTitle = document.title # title по умолчанию
        @prevHref = window.location.pathname
        isOpenPopup = false
        @$popupBlockContent = $('.popup-page__content')
        @$arrowRight = '<div class="slider__arrow slider__arrow-right"></div>';
        @$arrowLeft = '<div class="slider__arrow slider__arrow-left"></div>';
        new window.TabBlock()
        new window.FeedbackForm()
        new window.Video()
        loc = window.location.href
        if(loc.indexOf("news") == -1) 
          new window.NewsPopup()

        @initPopupPage()
        @initCloseButton()
        @initSlider()
        @initSmoothScroll()
        @initTables()
        @initHeader()
        @prevTitle = document.title

#        history.replaceState({popupPage: false, hrefPage: self.prevHref}, self.prevTitle, self.prevHref)

        # close popup
        $('.popup-page').click (event) ->
            if ($(window).width() >= '1088')
                if $(event.target).closest('.popup-page__content').length
                    return
                self.closePopup()
                document.title = window.defaultPageTitle

        # Для работы кнопки назад в браузере
        window.onpopstate = (event) ->
            event.preventDefault()
            if event.state
                window.location.href = event.state.hrefPage
            else
                window.location.href = '/'
            window.location.reload()

    initPopupPage: ->
        self = this
        $('.js-popup-link').each ->
            unless $(this).attr("data-event-init") == "true"
                $(this).attr("data-event-init", "true")

                $(this).on 'click': (event) ->
                    event.stopPropagation();
                    event.preventDefault();
                    pageLink = $(this).attr('href');
                    title = $(this).text()
                    popupLink = this
                    self.loadPage(pageLink, title)


    loadPage: (popupPageHref, title)->
        self = this
        #    if window.location.pathname != popupPageHref
        $.ajax
            url: popupPageHref
            type: 'GET'
            dataType: 'html'
            data: {'ajax_load_page': true}
            success: (data) ->
              meta_title = $(data).find('h1').html()
              self.$popupBlockContent.html(data)

              if (meta_title)
                document.title = meta_title

              unless self.isOpenPopup
                self.openPopup(popupPageHref, title)

    openPopup: (pageLink, title) ->
        self = this
        $('html').css("overflow-y", "hidden")
        self.prevHref = window.location.pathname
        self.prevTitle = document.title
        $('.popup-page').toggleClass('popup-page--active')
        $('.popup-page').scrollTop(0)
        setTimeout( ->
            $('.popup-page').addClass('popup-page--bg-white')
            new window.TabBlock()
            new window.FeedbackForm()
            new window.NewsPopup()
            new window.Video()
            self.initCloseButton()
            self.initSlider()
            self.initTables()
            self.initSmoothScroll()
            self.initHeader()
        ,100);

        history.pushState({popupPage: true, hrefPage: pageLink}, title, pageLink)
        self.isOpenPopup = true


    initHeader: ->
      personalTopContent = document.getElementById("top-content-personal");
      personalAfterImg = document.getElementById("top-content-after-img");
      tabToggle = document.getElementById("tab-toggle-container");
        
      if(window.innerWidth < 768)
        if(personalTopContent != null && tabToggle != null)
          personalTopContent.classList.remove('partner-card__top-content--disable')
          personalAfterImg.classList.add('partner-card__top-content--disable')
        
          tabToggle.style.display = "none";
          $('#tab-toggle-main').click (event) ->
            if (tabToggle.style.display == "none") 
                tabToggle.style.display = "block";
            else 
                tabToggle.style.display = "none";


    closePopup: ->
      self = this
      if self.isOpenPopup
        history.replaceState({popupPage: false, hrefPage: self.prevHref}, self.prevTitle, self.prevHref)
        setTimeout( ->
          self.prevHref = window.location.pathname
        , 100)
        $('.popup-page').removeClass('popup-page--active');
        $('.popup-page').removeClass('popup-page--bg-white');
        $('html').css("overflow-y", "auto")
        self.isOpenPopup = false

    initSmoothScroll: ->
      $root = $('.popup-page.popup-page--active')

      $('a[href^="#"]').on 'click': (event) ->
        event.preventDefault()
        $( $(this).attr('href') )[0].scrollIntoView({block: 'start', behavior: 'smooth'});
      #   $root.animate(
      #     scrollTop: .offset().top - 70
      #   , 500)

      return false

    initCloseButton: ->
      self = this
      unless ($('.popup-close-button-js').attr('data-init'))
        $('.popup-close-button-js').attr('data-init', true)
        $('.popup-close-button-js').on 'click': (event) ->
          if(window.history && !self.isOpenPopup)
            event.preventDefault()
            event.stopPropagation()
            window.history.back()
          self.closePopup()
          document.title = window.defaultPageTitle
      $('.popup-close-button-img-js').on 'click': (event) ->
        self.closePopup() 

    initSlider: ->
      $('.popup-page__slider').not('.slick-initialized').slick({
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0',
          nextArrow: @$arrowRight,
          prevArrow: @$arrowLeft,
          centerMode: true,
      })

      $('.publication-inner__slider-item').each ->
          sliderImage = $(this).find('img')

          if sliderImage.height() < 512
              sliderImage.css('width','auto');
              sliderImage.css('height','100%');
          else
              sliderImage.css('width','100%');
              sliderImage.css('height','auto');


    initTables: ->
        $('table').each ->
            $(this).wrap("<div class='table-wrapper'></div>")
#            wrapper = $(this).parents()[0];
#            new SimpleBar(wrapper)

