class window.Video
  constructor: ->
    # @load_video = undefined # Флаг разрешающий создание iframe
    @showVideoBtn = $('.js-show-video')
    @closeControlls = $('.popup-page-mask, .popup-block__close-video-block')
    @videoOverlay = $('.youtube-video-overlay')
    @init()

  init: =>
    toggleVideo = -> # Видео ставится на паузу при закрытии popup окна
      if $('#popup-video').hasClass('active')
        $('#player-frame').get(0).contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
      else
        if $('#player-frame').length
          $('#player-frame').get(0).contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
      return


    closeVideo = ->
      $('.popup-video-wrapper').removeClass 'active'
      setTimeout(
        ->
          $('.popup-page-mask').removeClass("popup-page-mask--active")
      , 130)
      $('.popup-page--active').removeClass 'overflow-hidden'  
      $('#player-frame').get(0).outerHTML = "";
      if ($('.parter-card').width() >= 768)
        $('.parter-card--personal_popup').css({ position: "fixed" });

    showVideo = ->
      $('.popup-page-mask').addClass 'popup-page-mask--active'
      $('.popup-video-wrapper').addClass 'active'
      return

    @closeControlls.click closeVideo
    @closeControlls.click toggleVideo

    @showVideoBtn.click ->

      self = this
      showVideo $(this)
      if ($(window).width() >= 768)
        $('.popup-page--active').addClass 'overflow-hidden'
      if ($('.parter-card').width() >= 768)
        $('.parter-card--personal_popup').css({ position: "revert" });

      
      if(self.dataset.link)
        iframe_url = "https://www.youtube.com/embed/#{self.dataset.link}?autoplay=1&autohide=1&border=0&wmode=opaque&enablejsapi=1"
      else
        iframe_url = 'https://www.youtube.com/embed/zbMGrORO8Xg?autoplay=1&autohide=1&border=0&wmode=opaque&enablejsapi=1'

      iframe = $('<iframe/>', 'id': 'player-frame', 'frameborder': '0', 'allow': 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture', 'allowfullscreen': 'allowfullscreen', 'rel': '0', 'src': iframe_url, 'width': '100%', 'height': '100%')
      # if (self.load_video == undefined)
      $('.video-block--youtube').append(iframe)
        # self.load_video = false
      toggleVideo()
      return

    pressed = (e) -> # Закрываем видео при нажатии клавиш
      closeVideo()
      toggleVideo()
      return

    @videoOverlay.click ->
      if $('#player-frame').length
        $(@).addClass('hidden')
        $('#player-frame').get(0).contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*')
    
    window.captureEvents Event.KEYPRESS
    window.onkeypress = pressed


