class window.PopupImg
  constructor: ->
    @init();

  init: =>
    self = this
    $('.js-popup-img').on 'click': (event) ->
      event.stopPropagation()
      event.preventDefault()
      self.openPopupImg($(this).find('img').attr('src'))

    $('.popup-close-button-img-js').on 'click': (event) ->
      self.closePopup()
      
  closePopup: =>
    $('.popup-img__modal').removeClass('active')
    $('.popup-img__modal').removeClass('popup-page--bg-white')
    $('html').css("overflow-y", "auto")

  openPopupImg: (imgLink) ->
    $('html').css("overflow-y", "hidden")
    $('.popup-img__modal').addClass('active')
    $('.popup-img__modal').addClass('popup-page--bg-white')
    $('.popup-img__modal').find('img').attr('src', imgLink) 
