class window.NewsPopup
  constructor: ->
    # @publicationBlockBody = $('.publication-block > .container')
    @openSectionButtonInit()
    @removeTagButtonInit()
    @morePublicationButtonsInit()

  morePublicationButtonsInit: ->
    self = this
    $('.js-more-items').on "click", (event) ->
      event.preventDefault()
      event.stopPropagation()
      href = $(this).attr('href')
      $.ajax
        url: href
        type: 'get'
        dataType: 'html'
        success: (result) ->
          $('.more-button-block').remove()
          $('.js-result-container').append($(result).find('.js-result-container > .row'))
          $('.js-result-container').append($(result).find('.js-result-container > .more-button-block'))
          # new window.PopupPage()
          self.morePublicationButtonsInit()
          return

  openSectionButtonInit: ->
    self = this 
    for openSectionButton in $('.js-open-section')
      unless $(openSectionButton).attr("data-event-init") == "true"

        $(openSectionButton).attr("data-event-init", "true")

        $(openSectionButton).on "click", (event) ->
          event.preventDefault()
          section = $(this).attr('data-section-id')

          $('.publications-tab__item').removeClass('publications-tab__item--active')
          $("a[data-section-id='" + section + "']").addClass('publications-tab__item--active')

          href = self.getFilterUrl()
          self.ajaxLoadNews (href)

  
  removeTagButtonInit: ->
    self = this
    $('.js-remove-tag-button').click ->
      $('.js-tag-item').remove()

      href = self.getFilterUrl()
      self.ajaxLoadNews (href)  


  ajaxLoadNews: (href) ->
    self = this
    section = $('.publications-tab__item--active').attr('data-section-id')

    $.ajax
      url: href
      type: 'get'
      dataType: 'html'
      success: (result) ->
        $('.js-result-container').html($(result).find('.js-result-container').html())
        # history.pushState({popupPage: false, hrefPage: href}, 'Title', href)
        # new window.PopupPage()
        if section == 'all'
          self.openSectionButtonInit()
        setTimeout ->
          self.morePublicationButtonsInit()
        , 300

        return


  getFilterUrl: ->
    language_url = $("meta[http-equiv='content-language']").attr('content')
    href = ''
    personaCode = $('.publications-tab__item--active').attr('data-personal-code')
    
    if $('.publications-tab__item--active').length > 0
      section = $('.publications-tab__item--active').attr('data-section-id')
    else
      section = 'all'

    if (section != 'all' && section)
      if (language_url == 'ru')
        href+='/team/'+personaCode+'/?section-id='+section  
      else
        href+='/en/team/'+personaCode+'/?section-id='+section
    else
      if (language_url == 'ru')
        href+='/team/'+personaCode
      else
        href+='/en/team/'+personaCode

    return href


