class window.TabBlock
    constructor: ->
        self = this

        @initTab()
        @initLink()

    initTab: ->
        $('.tab-item:not(.tab-item--active)').fadeOut();

    initLink: ->
        $('.link-item').on 'click': ->
            event.preventDefault();
            tabItems = $('.tab-item');
            typeTab = $(this).data('tab');

            tabItems.fadeOut( "slow" );
            $('.link-item').removeClass('link-item--active');
            $(this).addClass('link-item--active');

            $.each tabItems, (i) ->
                if $(tabItems[i]).data('tab') == typeTab
                    $(tabItems[i]).fadeIn( "slow" );


