require ('bootstrap-sass-loader!../bootstrap/bootstrap-sass.config.js');
require("../app/popup_page.coffee");

$(document).ready ->
  new window.PopupPage()
  new window.TeamPage()

  
class window.TeamPage
  constructor: ->
    self = @
    isOpenPopup = false
    @$popupBlockContent = $('.popup-person-page__content')
    @initPublications()



  initPublications: ->
    self = @

    $('body').on 'click', '.publications-tab__item', ->
      $('.publications-tab__item').removeClass('publications-tab__item--active')
      $(@).addClass('publications-tab__item--active')

      switch $(@).attr('data-type')
        when 'all' then $('.link-item[data-tab="publications-all"]').trigger('click')
        when 'author' then $('.link-item[data-tab="publications-author"]').trigger('click')
        when 'person' then $('.link-item[data-tab="publications-person"]').trigger('click')

      $('.link-item[data-tab="publications-all"]').addClass('link-item--active')
    

    $('body').on 'click', '.partner-card__tab-links .link-item', ->
      type = $(@).attr('data-tab')
      # new window.PopupPage()

      if (type == "publications-all" || type == "publications-author" || type == "publications-person")
        $('.partner-card__work-info .publications-tab').addClass('active')     
      else 
        $('.partner-card__work-info .publications-tab').removeClass('active')

      if type == "publications-all"
        $('.partner-card__work-info .publications-tab__item').removeClass('publications-tab__item--active')
        $('.partner-card__work-info .publications-tab__item[data-type="all"]').addClass('publications-tab__item--active')
    
    $('body').on 'click', 'a.js-popup-person-link', (event)->
      event.stopPropagation();
      event.preventDefault();
      pageLink = $(this).attr('href');
      title = $(this).text()
      popupLink = this
      self.loadPage(pageLink, title)

    $('.popup-person-page').on 'click', '.popup-close-button-js', ->
      $('.popup-person-page').removeClass('popup-person-page--active');
      $('.popup-person-page').removeClass('popup-person-page--bg-white');
      $('html').css("overflow-y", "auto")
      self.isOpenPopup = false

    $('.popup-person-page').on 'click', ->
      $('.popup-person-page').removeClass('popup-person-page--active');
      $('.popup-person-page').removeClass('popup-person-page--bg-white');
      $('html').css("overflow-y", "auto")
      self.isOpenPopup = false

    $('.popup-person-page .popup-person-page__content').on 'click', (event)->
      unless $(event.originalEvent.target).hasClass('base-button__decoration')
        event.stopPropagation()

  loadPage: (popupPageHref, title)->
    self = this

    $.ajax
        url: popupPageHref
        type: 'GET'
        dataType: 'html'
        data: {'ajax_load_page': true}
        success: (data) ->
          meta_title = $(data).find('h1').html()
          self.$popupBlockContent.html(data)

          if (meta_title)
            document.title = meta_title

          unless self.isOpenPopup
            self.openPopup(popupPageHref, title)


  openPopup: (pageLink, title) ->
      self = this
      $('html').css("overflow-y", "hidden")
      self.prevHref = window.location.pathname
      self.prevTitle = document.title
      $('.popup-person-page').toggleClass('popup-person-page--active')
      $('.popup-person-page').scrollTop(0)
      setTimeout( ->
          $('.popup-person-page').addClass('popup-person-page--bg-white')
      ,100);      
      self.isOpenPopup = true

